<template>
  <div class="auth-wrapper px-2">
    <div class="auth-inner py-2">
      <div class="brand">
        <img
          :src="imgUrl"
          class="brand-img"
          alt="Payment Info"
        >
      </div>

      <!-- Login v1 -->
      <b-card class="mb-0">
        <validation-observer
          ref="verifyMetadataForm"
          v-slot="{passes}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="passes(submitMetadata)"
          >

            <b-row>
              <!-- Payment Information -->
              <b-col
                sm="12"
              >
                <h4>Payment Information</h4>
                <h5>Bank Details</h5>
                <!-- Bank Name -->
                <b-form-group
                  label-for="bank_name"
                  label="Select Bank Name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Name"
                    vid="bank_name"
                  >
                    <b-form-select
                      id="bank-name"
                      v-model="payment_information.bank_name"
                      :options="banks_options"
                      name="bank-name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Bank Account Name -->
                <b-form-group
                  label-for="bank_account_name"
                  label="Enter Bank Account Name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Account Name"
                    vid="bank_account_name"
                  >
                    <b-form-input
                      id="bank-account-name"
                      v-model="payment_information.bank_account_name"
                      name="bank-account-name"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      onkeydown="return /[a-z]/i.test(event.key)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Bank Account Number -->
                <b-form-group
                  label-for="bank_account_number"
                  label="Enter Bank Account Number"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Account number"
                    vid="bank_account_number"
                  >
                    <b-form-input
                      id="bank-account-number"
                      v-model="payment_information.bank_account_number"
                      name="bank-account-number"
                      :state="errors.length > 0 ? false:null"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Bank Branch -->
                <b-form-group
                  label-for="bank_branch"
                  label="Enter Bank Branch"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bank Branch"
                    vid="bank_branch"
                  >
                    <b-form-input
                      id="bank-branch"
                      v-model="payment_information.bank_branch"
                      name="bank-branch"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      onkeydown="return /[a-z]/i.test(event.key)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="isLoading"
              class="w-25 float-right"
            >
              Submit
              <b-spinner
                v-if="isLoading"
                small
              />
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BButton, BForm, BFormInput, BFormGroup, BCard, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BSpinner,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      // Member Type
      banks_options: [],

      show_kin_id_document_size_error: false,
      show_enter_valid_mobile_money_name: false,
      isValidPhoneNumber: true,

      // Payment information
      payment_information: {
        bank_account_name: '',
        bank_account_number: '',
        bank_branch: '',
        bank_name: '',
      },

      // validation rules
      required,

      isLoading: false,
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/PAVRISK.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/PAVRISK.png')
        // this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  async mounted() {
    await this.$http
      .get('/banks')
      .then(response => {
        response.data.data.forEach(bank => {
          this.banks_options.push({ text: bank.name, value: bank.name })
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    submitMetadata() {
      this.isLoading = true
      const formData = new FormData()
      formData.append('content_id', this.$router.currentRoute.params.id)
      Object.keys(this.payment_information).some(key => formData.append(key, this.payment_information[key]))
      this.$http.post('/membership/contributor/payment-info/upload', formData)
        .then(response => {
          console.log(response.data)
          this.$router.replace({ name: 'payment-info-success' })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Data uploaded successfully!',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
        })
        .catch(error => {
          console.log(error.response.data.data)
          this.$refs.verifyMetadataForm.setErrors(error.response.data.data)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'The data entered is invalid.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.brand {
  padding: 10px;
  width: 50%;
}
.brand-img {
  width: 140px;
  margin-left: auto;
  margin-right: auto;
}
</style>
